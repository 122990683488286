import { useState } from "react";

import i18n, { getLanguage } from "../../utils/i18n";

interface Hook {
    language: string;
    updateLanguage: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const useLanguageSelector = (): Hook => {
    const [language, setLanguage] = useState<string>(getLanguage());

    const updateLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setLanguage(e.target.value as string);
        i18n.changeLanguage(e.target.value);
    };

    return {
        language,
        updateLanguage,
    };
};

export default useLanguageSelector;

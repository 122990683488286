import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";
import { useTranslation } from "react-i18next";

import useLanguageSelector from "../../hooks/useLanguageSelector";

type Props = {
    formClasses?: string;
    selectClasses?: string;
    fullWidth?: boolean;
};

const LanguageSelector = ({
    formClasses,
    selectClasses,
    fullWidth,
}: Props): JSX.Element => {
    const { language, updateLanguage } = useLanguageSelector();
    const { t } = useTranslation();

    return (
        <FormControl fullWidth={fullWidth} className={formClasses}>
            <Select
                className={selectClasses}
                labelId="language-selector"
                value={language}
                onChange={updateLanguage}
            >
                <MenuItem value="en">{t("English")}</MenuItem>
                <MenuItem value="nl">{t("Dutch")}</MenuItem>
                <MenuItem value="fr">{t("French")}</MenuItem>
            </Select>
        </FormControl>
    );
};

export default LanguageSelector;

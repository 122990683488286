import { navigate } from "gatsby";
import React from "react";

import * as styles from "../../styles/export.module.scss";
import * as style from "./style.module.scss";

export enum LogoTypes {
    BRAND = "BRAND",
    SOMNILOG = "SOMNILOG",
}
type Props = {
    type: LogoTypes;
    color?: string;
    width?: number;
    url?: string;
};

const getLogo = (type: string, width?: number) => {
    if (type === LogoTypes.BRAND) {
        return (
            <svg
                width={width ?? 50}
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 36.85 36.85"
            >
                <g
                    id="Group_15_00000004515853815402609650000009331446644646055094_"
                    transform="translate(0 0)"
                >
                    <path
                        id="Path_26_00000013159310585471658040000003359588115909074316_"
                        fill="currentColor"
                        d="M11.51,12.79c-1.47-0.04-2.9,0.54-3.92,1.6
c-1.04,1.09-1.59,2.55-1.54,4.05c-0.05,1.5,0.51,2.96,1.54,4.05c1.02,1.06,2.45,1.64,3.92,1.6c1.47,0.04,2.89-0.54,3.9-1.6
c1.05-1.08,1.61-2.55,1.56-4.05c0.05-1.51-0.51-2.97-1.56-4.05C14.4,13.33,12.98,12.75,11.51,12.79"
                    />
                    <path
                        id="Path_30_00000072239840610790328690000001548335370556853163_"
                        fill="currentColor"
                        d="M25.35,12.79c-1.47-0.04-2.9,0.54-3.92,1.6
c-1.04,1.09-1.59,2.55-1.54,4.05c-0.05,1.5,0.51,2.96,1.54,4.05c1.02,1.06,2.45,1.64,3.92,1.6c1.47,0.04,2.89-0.54,3.9-1.6
c1.05-1.08,1.61-2.55,1.56-4.05c0.05-1.51-0.51-2.97-1.56-4.05C28.23,13.33,26.81,12.75,25.35,12.79 M27.82,21.22
c-1.29,1.36-3.45,1.42-4.81,0.13c-0.04-0.04-0.09-0.08-0.13-0.13c-0.68-0.76-1.04-1.75-1-2.77c-0.04-1.01,0.32-2,1-2.75
c1.26-1.36,3.38-1.45,4.75-0.19c0.07,0.06,0.13,0.13,0.19,0.19c0.68,0.75,1.04,1.74,1,2.75C28.86,19.47,28.5,20.46,27.82,21.22"
                    />
                </g>
            </svg>
        );
    }
    if (type === LogoTypes.SOMNILOG) {
        return (
            <svg
                version="1.1"
                width={width ?? 200}
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 105.55 36.96"
            >
                <g
                    id="Group_15_00000163051918057122549980000017031113760146121132_"
                    transform="translate(0 0)"
                >
                    <path
                        id="Path_25_00000075120380745883696920000007366200784848596890_"
                        fill="currentColor"
                        d="M14.5,17.68l-1.6-0.41
		c-1.14-0.26-1.71-0.69-1.71-1.3c0.02-0.41,0.25-0.77,0.62-0.95c0.42-0.26,0.9-0.4,1.4-0.39c0.53-0.01,1.06,0.12,1.53,0.38
		c0.44,0.23,0.78,0.6,0.99,1.05l1.78-0.74c-0.31-0.76-0.87-1.4-1.59-1.8c-0.77-0.45-1.65-0.68-2.55-0.67
		c-1.08-0.04-2.14,0.26-3.02,0.88c-0.76,0.53-1.2,1.4-1.18,2.33c0,1.47,1.03,2.47,3.1,2.99l1.82,0.45c1.04,0.32,1.56,0.82,1.56,1.5
		c-0.02,0.42-0.27,0.79-0.64,0.98c-0.48,0.29-1.03,0.43-1.59,0.41c-0.59,0-1.16-0.18-1.64-0.53c-0.51-0.37-0.91-0.89-1.14-1.48
		l-1.78,0.76c0.34,0.9,0.94,1.66,1.73,2.2c0.83,0.56,1.81,0.85,2.82,0.83c1.1,0.04,2.18-0.29,3.06-0.95
		c0.76-0.54,1.21-1.41,1.21-2.34C17.66,19.26,16.61,18.2,14.5,17.68"
                    />
                    <path
                        id="Path_26_00000090291608148931145990000013149505310330939326_"
                        fill="currentColor"
                        d="M24.9,12.85c-1.47-0.04-2.9,0.54-3.92,1.6
		c-1.04,1.09-1.59,2.55-1.54,4.05c-0.05,1.5,0.51,2.96,1.54,4.05c1.02,1.06,2.45,1.64,3.92,1.6c1.47,0.04,2.89-0.54,3.9-1.6
		c1.05-1.08,1.61-2.55,1.56-4.05c0.05-1.51-0.51-2.97-1.56-4.05C27.79,13.39,26.37,12.8,24.9,12.85"
                    />
                    <path
                        id="Path_27_00000115505894923712886750000002679146261470297520_"
                        fill="currentColor"
                        d="M44.8,12.85c-1.5-0.03-2.9,0.75-3.66,2.04
		c-0.26-0.62-0.72-1.15-1.3-1.49c-1.21-0.72-2.71-0.72-3.93-0.02c-0.58,0.3-1.06,0.75-1.4,1.3h-0.09v-1.47h-1.91v10.62h1.99v-5.87
		c-0.02-0.83,0.26-1.65,0.78-2.3c0.45-0.61,1.17-0.98,1.93-0.98c0.68-0.07,1.35,0.18,1.82,0.66c0.42,0.64,0.61,1.4,0.54,2.16v6.32
		h1.99v-5.87c-0.02-0.83,0.26-1.65,0.78-2.3c0.45-0.61,1.17-0.98,1.93-0.98c0.68-0.07,1.35,0.18,1.82,0.66
		c0.42,0.64,0.61,1.4,0.54,2.16v6.32h1.99v-6.63c0.07-1.15-0.28-2.3-1-3.21C46.9,13.2,45.86,12.78,44.8,12.85"
                    />
                    <path
                        id="Path_28_00000020389120489497195640000007220164488509378227_"
                        fill="currentColor"
                        d="M56.87,12.85c-0.69,0-1.36,0.18-1.96,0.52
		c-0.58,0.3-1.06,0.75-1.4,1.3h-0.09v-1.47h-1.91v10.62h1.99v-5.87c-0.02-0.84,0.27-1.66,0.8-2.31c0.48-0.62,1.22-0.97,1.99-0.96
		c1.7,0,2.58,0.87,2.62,2.6v6.54h1.99v-6.68c0.07-1.15-0.3-2.29-1.05-3.17C59.07,13.18,57.98,12.78,56.87,12.85"
                    />
                    <path
                        id="Path_29_00000010280936754977424170000000125612708588922526_"
                        fill="currentColor"
                        d="M64.97,8.12c-0.37-0.01-0.74,0.14-1,0.41
		c-0.27,0.26-0.42,0.62-0.41,1c-0.01,0.37,0.14,0.73,0.41,1c0.55,0.55,1.44,0.55,1.99,0c0.27-0.26,0.42-0.62,0.41-1
		c0.01-0.37-0.14-0.73-0.41-1C65.71,8.27,65.35,8.12,64.97,8.12"
                    />

                    <rect
                        id="Rectangle_47_00000048473222064036510320000014141000881363517330_"
                        x="63.98"
                        y="13.19"
                        fill="currentColor"
                        width="1.99"
                        height="10.62"
                    />

                    <rect
                        id="Rectangle_48_00000141448303950416688310000010935529241295548085_"
                        x="69.11"
                        y="8.3"
                        fill="currentColor"
                        width="1.99"
                        height="15.52"
                    />
                    <path
                        id="Path_30_00000052070179572492023560000004066985195033289147_"
                        fill="currentColor"
                        d="M78.82,12.85c-1.47-0.04-2.9,0.54-3.92,1.6
		c-1.04,1.09-1.59,2.55-1.54,4.05c-0.05,1.5,0.51,2.96,1.54,4.05c1.02,1.06,2.45,1.64,3.92,1.6c1.47,0.04,2.89-0.54,3.9-1.6
		c1.05-1.08,1.61-2.55,1.56-4.05c0.05-1.51-0.51-2.97-1.56-4.05C81.71,13.39,80.29,12.8,78.82,12.85 M81.29,21.28
		c-1.29,1.36-3.45,1.42-4.81,0.13c-0.04-0.04-0.09-0.08-0.13-0.13c-0.68-0.76-1.04-1.75-1-2.77c-0.04-1.01,0.32-2,1-2.75
		c1.26-1.36,3.38-1.45,4.75-0.19c0.07,0.06,0.13,0.13,0.19,0.19c0.68,0.75,1.04,1.74,1,2.75C82.33,19.52,81.97,20.52,81.29,21.28"
                    />
                    <path
                        id="Path_31_00000151508623944495066880000006308721721928684162_"
                        fill="currentColor"
                        d="M94.79,13.19v1.47H94.7
		c-0.38-0.57-0.91-1.03-1.53-1.33c-0.65-0.33-1.37-0.49-2.09-0.49c-1.38,0-2.68,0.6-3.58,1.65c-0.98,1.1-1.51,2.53-1.47,4.01
		c-0.03,1.48,0.49,2.92,1.47,4.03c0.9,1.04,2.2,1.63,3.58,1.63c0.73,0.01,1.44-0.16,2.09-0.49c0.62-0.3,1.14-0.76,1.53-1.33h0.09
		v1.02c0.06,0.98-0.27,1.94-0.91,2.69c-0.67,0.67-1.59,1.03-2.54,0.98c-0.67,0.01-1.32-0.18-1.86-0.56
		c-0.53-0.37-0.92-0.89-1.13-1.5l-1.89,0.78c0.16,0.46,0.39,0.88,0.69,1.26c0.31,0.38,0.68,0.71,1.09,0.98
		c0.44,0.28,0.92,0.5,1.42,0.64c0.54,0.15,1.1,0.23,1.67,0.23c1.45,0.07,2.86-0.47,3.9-1.47c1.01-1.08,1.54-2.53,1.45-4.01V13.19
		H94.79z M93.82,21.28c-0.6,0.7-1.48,1.09-2.4,1.06c-0.92,0.01-1.8-0.38-2.4-1.08c-0.67-0.76-1.02-1.74-0.98-2.75
		c-0.04-1.01,0.31-1.99,0.98-2.75c0.6-0.7,1.48-1.1,2.4-1.08c0.92-0.02,1.81,0.38,2.4,1.08c0.66,0.76,1.01,1.74,0.98,2.75
		C94.84,19.52,94.49,20.51,93.82,21.28"
                    />
                </g>
            </svg>
        );
    }
};

const Logo = ({
    type,
    color = styles.nightbluelight,
    width,
    url,
}: Props): JSX.Element => {
    return (
        <div
            onClick={() => {
                if (url) {
                    navigate("/dashboard/");
                }
            }}
            className={`${url ? style.linkSvg : ""}`}
            style={{ color }}
        >
            {getLogo(type, width)}
        </div>
    );
};

export default Logo;
